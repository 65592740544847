$.fn.scaleWeb = function(options) {
    var defaults = {
        obj: $(this),
        popupEl: '.popup__content',
        scaleMode: '',
        isInitAfterRescale: true,
        isRefreshAfterRescale: false,
        design: {
            desktop: {
                width: 2100,
                height: 1220
            },
            mobile: {
                width: 768,
                height: 1200
            }
        },
        viewPortIsMobile: function(width = $(window).outerWidth()) {
            // console.log(width);
            return !(width > 1023);
        },
        addInCSS: {},
        rescaleEl: "#wrapper",
    }

    var settings = $.extend(defaults, options);

    $(window).on("resize", function(){
        var device = {
            width: $(window).outerWidth(),
            height: $(window).outerHeight()
        };

        let ratioWidth = 0, 
            marginLeft = 0, 
            marginTop = 0,
            scaleRatio = 0,
            scaleCSS = {},
            unionCSS = {};

        // console.log(settings.viewPortIsMobile(device.width)); 
        // console.log(device);

        if (!settings.viewPortIsMobile(device.width)) {
            ratioWidth = device.width / settings.design.desktop.width;
        } else {
            ratioWidth = device.width / settings.design.mobile.width;
        }

        scaleRatio = ratioWidth;

        scaleCSS = {
            transform: "scale("+scaleRatio+")",
            marginTop: marginTop+"px",
            marginLeft: marginLeft+"px"
        }

        unionCSS = $.extend(scaleCSS, settings.addInCSS);

        // console.log(unionCSS);

        $(settings.obj).css(unionCSS);

        let parentHeight = $(settings.rescaleEl).height() * scaleRatio;

        $(settings.rescaleEl).parent().css({
            height: parentHeight+"px",
            overflow: 'hidden'
        });        

        $(settings.popupEl).css({
            transform: "scale("+scaleRatio+")"
        });
    }).resize();

    // if (settings.isInitAfterRescale) {
    //     $(window).resize();
    // };

}