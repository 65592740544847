import initIcons from "./_svg-icons.js";
// import "./_social";
import "./_lightbox";

import "twbs-pagination";

$.fn.loadAjax = function(options){
  var defaults = {
    obj: $(this),
    language: {
      'loading': 'Content is loading...',
      'error': 'Error occured!',
      'noresult': 'There is no result!'
    }
  }
  var settings = $.extend(defaults, options);

  const loadAjax = {
    getCore: () => settings.obj.data('loadajax-core'),
    getPage: () => {
      let laCore = loadAjax.getCore();
      let laPage = $(`[data-loadajax-core="${laCore}"]`).attr('data-loadajax-page');
      return laPage;
    },
    getCate: () => {
      let laCore = loadAjax.getCore();
      let laCate = $(`[data-loadajax-core="${laCore}"]`).attr('data-loadajax-cate');
      return laCate;
    },
    getType: () => {
      let laCore = loadAjax.getCore();
      let laType = $(`[data-loadajax-core="${laCore}"]`).attr('data-loadajax-type');
      return laType;
    },
    getUrl: () => settings.obj.data('loadajax-url'),
    getObj: () => settings.obj.data('loadajax-obj'),
    getObjViewall: () => settings.obj.data('loadajax-obj-viewall'),
    setPage: (newPage) => {
      let laCore = loadAjax.getCore();
      $(`[data-loadajax-core="${laCore}"]`).attr('data-loadajax-page', newPage);
      return true;
    },
    setCate: (newCate) => {
      console.log(newCate);
      let laCore = loadAjax.getCore();
      $(`[data-loadajax-core="${laCore}"]`).attr('data-loadajax-cate', newCate);

      // if ($("[data-loadajax-pagination]").length > 0) {

      // }

      return true;
    },
    setViewAll: (url) => {
      let laViewAll = loadAjax.getObjViewall();
      if ($(laViewAll).length > 0) {
        $(laViewAll).attr("href", url);
      }
      return true;
    },
    setInitActiveTab: () => {
      let currentUrl = window.location.href;

      if (currentUrl.indexOf('.1.html') > 0) {
        let currentCate = currentUrl.split('/').pop().split('.1.html').shift();
        $(`[data-loadajax]`).removeClass("active");
        $(`[data-loadajax-cate="${currentCate}"]`).addClass("active");
        $(`[data-loadajax-cate="${currentCate}"]`).trigger("click");

        return true;
      }
      return false;
    },
    isNull: (result) => (result.replace(/(\s)+/g,"").length < 20),
    clearObj: () => {
      let laObj = loadAjax.getObj();
      $(laObj).empty();
      return true;
    },
    displayLoadingBlock: (text = '', theme = '') => {
      let data_text = (text.length > 0) ? text : settings.language.error;
      let default_theme = `<div class="loadajax-inform loadAjaxWait">
          <span class="label">${data_text}</span>
      </div>`;
      let data_theme = (theme.length > 0) ? theme : default_theme;
      let laObj = loadAjax.getObj();
      $(laObj).append(data_theme);
    },
    clearLoadingBlock: function() {
      let laObj = loadAjax.getObj();
      $(laObj+' .loadAjaxWait').remove();
      return true;
    },
    initPagination: () => {

      if ($("[data-loadajax-objpage]").length > 0) {
        let laPagination = $("[data-loadajax-objpage]");
        let laTotal = parseInt(laPagination.data("loadajax-total"));
        let laLimit = parseInt(laPagination.data("loadajax-limit"));
        let laCate = laPagination.data("loadajax-cate");
        let modulusPage = laTotal % laLimit > 0 ? 1 : 0;

        let totalPages = parseInt(laTotal / laLimit) + modulusPage;

        // console.log($('[data-loadajax-objpage]').data("loadajax-objpage"))

        // console.log(totalPages);

        $($('[data-loadajax-objpage]').data("loadajax-objpage")).twbsPagination({
          startPage: 1,
          totalPages: parseInt(totalPages),
          visiblePages: 5,
          first: '&laquo;',
          prev: '&lsaquo;',
          next: '&rsaquo;',
          last: '&raquo;',
          onPageClick: function(event, page) {

            event.preventDefault();
        
            // Cleanup & Display loading block

            loadAjax.clearObj();
            loadAjax.displayLoadingBlock(settings.language.loading);

            // Complete Ajax URL

            let configUrl = loadAjax.getUrl();

            // -- Check Cate, Page

            configUrl = configUrl.replace(':cate:', loadAjax.getCate());
            configUrl = configUrl.replace(':page:', page);

            let configType = loadAjax.getType();
            let loadType = $(this).data('loadajax-type');

            // console.log(configUrl);

            loadAjax.callAjax(configUrl, configType, loadType);         

          }}
        )
      }
      
    },

    callAjax: (configUrl, configType, loadType) => {

      $.ajax({
        url: configUrl,
        dataType: configType,
        beforeSend: function(){
        },
        error: function(){
          loadAjax.clearObj();
          loadAjax.displayLoadingBlock();
        },
        success: function(result){
          if (loadAjax.isNull(result)) {
            switch (loadType) {
              case 'replace': 
                loadAjax.clearLoadingBlock();
                loadAjax.displayLoadingBlock(settings.language.noresult);
                break;
              case 'append':
                loadAjax.clearLoadingBlock();
                loadAjax.displayLoadingBlock(settings.language.noresult);

                $clickedObj.css({
                  'opacity': '0',
                  'pointer-events': 'none'
                });

                setTimeout(function(){
                  loadAjax.clearLoadingBlock();
                }, 2000);

                break;
            }
          } else {
            $('[data-loadajax]').css({
              'opacity': '1',
              'pointer-events': 'all'
            });
            loadAjax.clearLoadingBlock();

            let laObj = loadAjax.getObj();
            $(laObj).append(result);

            initIcons();

            if ($(laObj).find("[data-lightbox]").length > 0) {
              $(laObj).find("[data-lightbox]").each(function(){
                  $(this).lightBox({
                      objLightBox: $(this).attr('href'),
                      type: $(this).data('lightbox-type')
                  });
              }); 
            }

            // $("[data-callsocial]").each(function(){
            //     $(this).callSocial();
            // });

          }
        }
      })
    },
    handle: {
      loadBtn: () => {
        let laCore = loadAjax.getCore();
        // console.log(`data-loadajax="${laCore}"`);
        $(`[data-loadajax="${laCore}"]`).on('click', function(e){

          e.preventDefault();

          let $clickedObj = $(this);
          // Set Active Tab

          // $(this).parent().siblings().removeClass('active');
          // $(this).parent().addClass('active');

          $(this).parent().siblings().children().removeClass('active');
          $(this).addClass('active');

          // Set link view all

          let urlViewAll = $(this).data("loadajax-viewall");
          loadAjax.setViewAll(urlViewAll);
          
          // Cleanup & Display loading block

          let laType = $(this).data('loadajax-type');

          switch (laType) {
            case 'replace': 
              loadAjax.clearObj();
              loadAjax.displayLoadingBlock(settings.language.loading);
              break;
            case 'append': 
              loadAjax.displayLoadingBlock(settings.language.loading);
              break;
          }

          // Complete Ajax URL

          let configUrl = loadAjax.getUrl();

          // -- Check Cate

          let curCate = loadAjax.getCate();
          let newCate = $(this).data('loadajax-cate');

          if ((newCate !== undefined) && (newCate != curCate)) {
            loadAjax.setCate(newCate);
          }

          configUrl = configUrl.replace(':cate:', loadAjax.getCate());

          // -- Check Page

          let curPage = loadAjax.getPage();
          let newPage = $(this).data('loadajax-page');

          if ((newPage !== undefined) && (newPage != curPage)) {
            loadAjax.setPage(newPage);
          }

          // -- Check Load Type

          let loadType = $(this).data('loadajax-type');

          if ((loadType == 'append') || (loadType == 'next')) {
            newPage = parseInt(loadAjax.getPage()) + 1;
          }

          if (loadType == 'prev') {
            newPage = parseInt(loadAjax.getPage()) - 1;
          }

          loadAjax.setPage(newPage);

          configUrl = configUrl.replace(':page:', loadAjax.getPage());

          // Retrieve Data

          let configType = loadAjax.getType();

          // console.log(configUrl);

          loadAjax.callAjax(configUrl, configType, loadType);
          
          if (configType == 'replace') {
            loadAjax.initPagination();

            if ($("[data-loadajax-objpage]").length > 0) {
              $($('[data-loadajax-objpage]').data("loadajax-objpage")).empty();
            }
          }


        });
      }
    },
    execute: function(){
      loadAjax.handle.loadBtn();
    }
  }

  loadAjax.execute(); 
  loadAjax.setInitActiveTab();
  loadAjax.initPagination();

}