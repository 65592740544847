$.fn.music = function(options) {
    var defaults = {
        obj: $(this),
        isPlaying: false,
        toggleEl: '',
        toggleClass: 'active'
    }
    var settings = $.extend(defaults, options);

    $(settings.toggleEl).on("click", function(e){
        e.preventDefault();

        if (!settings.isPlaying) {
            $(settings.obj)[0].play();
            settings.isPlaying = true;
            $(settings.toggleEl).addClass(settings.toggleClass);
        } else {
            $(settings.obj)[0].pause();
            settings.isPlaying = false;
            $(settings.toggleEl).removeClass(settings.toggleClass);
        }

    });

}


