
import "select2";
import "select2/dist/css/select2.css";
import initIcons from "./_svg-icons.js";


$.fn.loadRank = function(options){
  var defaults = {
    obj: $(this),
    language: {
      'loading': 'Content is loading...',
      'error': 'Error occured!',
      'noresult': 'There is no result!'
    }
  }

  var settings = $.extend(defaults, options);

  const loadRank = {
    getCore: () => settings.obj.data("loadrank-core"),
    getData: (dataName = 'server') => {
      let lrCore = loadRank.getCore();
      let lrUrl = $(`[data-loadrank-core="${lrCore}"]`).attr("data-loadrank-" + dataName);
      return lrUrl;
    },
    setData: (dataName = 'server', dataValue = '') => {
      let lrCore = loadRank.getCore();
      $(`[data-loadrank-core="${lrCore}"]`).
                    attr("data-loadrank-" + dataName, dataValue);
      return true;
    },
    clearObj: () => {
      let objResult = loadRank.getData("objresult");
      $(objResult).empty();
      return true;
    },
    displayLoadingBlock: (text = '', theme = '') => {
      let data_text = (text.length > 0) ? text : settings.language.error;
      let default_theme = `<div class="loadajax-inform loadRankWait">
          <span class="label">${data_text}</span>
      </div>`;
      let data_theme = (theme.length > 0) ? theme : default_theme;
      let objResult = loadRank.getData("objresult");
      $(objResult).append(data_theme);
      return true;
    },
    clearLoadingBlock: function() {
      let objResult = loadRank.getData("objresult");
      $(objResult+' .loadRankWait').remove();
      return true;
    },
    generatePostData: () => {
      let configs = {
        url: loadRank.getData("url"),
        server: loadRank.getData("server"),
        classid: loadRank.getData("classid"),
        clan: loadRank.getData("clan"),
        type: loadRank.getData("type")
      };

      let postData = "rank_type=" + configs.type;

      if (configs.classid != '') {
        postData += "&class_id=" + configs.classid;
      }

      if (configs.server != '') {
        postData += "&server_id=" + configs.server;
      }

      return postData;

    },
    callAjax: () => {

        let configs = {
          url: loadRank.getData("url"),
          server: loadRank.getData("server"),
          classid: loadRank.getData("classid"),
          clan: loadRank.getData("clan"),
          type: loadRank.getData("type")
        };

        // let config = $.extend(defaults, options);
        // let config = defaults;
        
        let postData = loadRank.generatePostData();

        console.log(postData);

        $.ajax({
          url: configs.url,
          type: "POST",
          data: postData,
          beforeSend: () => {
            loadRank.clearObj();
            loadRank.displayLoadingBlock(settings.language.loading);
          },
          success: (data) => {
            loadRank.clearObj();
            let objResult = loadRank.getData("objresult");
            $(objResult).html(data);

            $('.form-control > select').each(function(){
              $(this).select2({
                minimumResultsForSearch: Infinity
              }); 
            });
            initIcons();

            loadRank.execute(); 
          },
          error: (err) => {
            console.log(err);
            loadRank.clearObj();
            loadRank.displayLoadingBlock();
          }
        });
    },
    handle: {
      changeServer: () => {
        let objHandle = loadRank.getData("objhandle");
        $(`[${objHandle}="server"]`).on("change", function(e){
          e.preventDefault();
          let value = $(this).val();
          loadRank.setData("server", value);

          loadRank.callAjax();
        });
      },
      changeClass: () => {
        let objHandle = loadRank.getData("objhandle");
        $(`[${objHandle}="classid"]`).on("change", function(e){
          e.preventDefault();
          let value = $(this).val();
          loadRank.setData("classid", value);

          loadRank.callAjax();
        });
      },
      changeType: () => {
        let objHandle = loadRank.getData("objhandle");
        $(`[${objHandle}="type"]`).on("click", function(e){
          e.preventDefault();
          let valueType = $(this).attr('data-loadrank-type');
          let valueClassId = $(this).attr('data-loadrank-classid');
          loadRank.setData("type", valueType);
          console.log(valueClassId);

          if (valueClassId != undefined) {
            loadRank.setData("classid", valueClassId);
            $(`[${objHandle}="classid"]`).val("0").trigger("change");
          } else {
            $(`[${objHandle}="classid"]`).val("").trigger("change");
          }

          loadRank.callAjax();

          $(this).parent().siblings().children().removeClass("active");
          $(this).addClass("active");
        });
      },
      changeClan: () => {
        let objHandle = loadRank.getData("objhandle");
        $(`[${objHandle}="clan"]`).on("change", function(e){
          e.preventDefault();
          let value = $(this).val();
          loadRank.setData("clan", value);

          loadRank.callAjax();
        });
      },
      changePage: () => {
        let objResult = loadRank.getData("objresult");
        let objHandle = loadRank.getData("objpage"); 
        $(objResult).on("click", objHandle, function(e){
          e.preventDefault();
          let value = $(this).attr("data-loadrank-control-page");

          var nextPage = 0;
          var curPage = loadRank.getData("page");
          
          switch (value) {
            case 'prev': 
              nextPage = parseInt(curPage) - 1;
              break;
            case 'next': 
              nextPage = parseInt(curPage) + 1;
              break;
            default: 
              nextPage = value;
          }

          let objResultPage = loadRank.getData("pre-page") + nextPage;
          if ($(objResultPage).length > 0) {
            $(objResultPage).siblings().removeClass("active");
            $(objResultPage).addClass("active");
            loadRank.setData("page", nextPage);
 
            $(this).parent().siblings().children().removeClass("active");
            $(`[data-loadrank-control-page=${nextPage}]`).addClass("active");
          } else {
            // console.log("")
          }
          
          // console.log(nextPage);

        })
      }
    },
    execute: () => {
      loadRank.handle.changeServer();
      loadRank.handle.changeClass();
      loadRank.handle.changeType();
      loadRank.handle.changeClan();
      loadRank.handle.changePage();
    },
    init: () => {
      loadRank.callAjax();
    } 
  }

  loadRank.init();
  loadRank.execute(); 

}